@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.google-login-button {
  background-color: white;
  width: fit-content;
  height: 40px;
  display: flex;
  place-content: center;
  place-items: center;
  padding: 0 8px;
  font-family: "Roboto", arial;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 2px grey;
  outline: none;
  border: none;
  cursor: pointer;
  padding-right: 40px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.google-login-button:focus {
  background-color: #eeeeee;
}

.google-login-button:hover {
  border: #eeeeee;
}

.google-login-button .google-logo img {
  height: 18px;
  width: 18px;
  margin-right: 24px;
}
